/**
 * File Uploader.
 */
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {PatternFormat} from 'react-number-format';
import {useEffect, useState} from 'react';
import Dropzone from 'react-dropzone-uploader';
import {useParams} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import {v4} from 'uuid';
import * as Yup from 'yup';

import axios from "./axios";
import NotFound from './NotFound';

// Form values and validation
const initialValues = {name: '', email: '', message: '', error: ''};
const validationSchema = Yup.object().shape({
    name: Yup.string().required().max(100),
    email: Yup.string().email().max(200),
    message: Yup.string()
});

const Uploader = () => {
    const {url} = useParams();
    const [transactionId, setTransactionId] = useState(v4());
    const [client, setClient] = useState();
    const [clientLoaded, setClientLoaded] = useState(false);
    const [uploadCompleted, setUploadCompleted] = useState(false);
    const [files, setFiles] = useState([]);

    // Update browser page title
    useEffect(() => {
        document.title = client ? `${client.campus} | RTasks Send` : 'RTasks Send';
    }, [client]);

    // fetch client details
    useEffect(() => {
        const getClient = async () => {
            try {
                const {data} = await axios.get(`/clients/${url}`);
                setClient(data);
            } catch (err) {
            } finally {
                setClientLoaded(true);
            }
        };

        getClient();
    }, [url]);

    // Get presigned URL for each file.
    const getUploadParams = async ({file, meta}) => {
        const {data} = await axios.post('/upload-url', {
            transactionId,
            clientId: client.id,
            fileName: meta.name,
            contentType: meta.type
        });

        return {
            body: file,
            method: 'put',
            headers: {'Content-Type': meta.type},
            meta: {fileUrl: data.url},
            url: data.url
        };
    };

    // Called every time a file's status changes.
    const handleChangeStatus = async (file, status, prevFiles) => {
        let newFiles = [...files];

        // File was added
        if (status === 'ready') {
            newFiles = [...newFiles, file];
        }

        // File was removed
        if (status === 'removed') {
            newFiles = newFiles.filter(e => e.meta.id !== file.meta.id);
        }

        // Update state if files changed
        if (newFiles.length !== files.length) {
            setFiles(newFiles);
        }

        if (status === 'done') {
            // record upload
            try {
                await axios.post('/clients/upload', {
                    url,
                    transactionId,
                    fileName: file.meta.name,
                    name: file.meta.form.name,
                    email: file.meta.form.email || null,
                    message: file.meta.form.message || null
                });
            } catch (err) {
            }

            // Display success message if upload completed.
            const inProgress = newFiles.some(e => e.meta.id !== file.meta.id && e.meta.status !== 'done');
            if (!inProgress) {
                setUploadCompleted(true);
                toast.dismiss();
                setTransactionId(v4());
                file.meta.form.resetForm();
                newFiles.forEach(e => {
                    e.remove();
                });
            }
        }
    };

    // Called when form is submitted
    const onSubmit = async ({name, email, message}, {resetForm}) => {
        initialValues.error = '';
        setUploadCompleted(false);
        files.forEach(e => {
            e.meta.form = {name, email, message, resetForm};
            if (e.meta.status === 'ready' || e.meta.status === 'error_upload') {
                e.restart();
            }
        });
        toast("Upload in progress", {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    };

    // waiting for load.
    if (!clientLoaded) {
        return <></>;
    }

    // render not found component.
    if (clientLoaded && !client) {
        return <NotFound/>;
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="bg-gradient-to-r from-indigo-500 via-indigo-700 to-indigo-900">
                <ToastContainer />
                {/* Header */}
                <div className="pt-12 sm:pt-16 xl:pt-20">
                    <div className="max-w-full lg:max-w-5xl mx-auto px-4 sm:px-6 xl:px-8">
                        <div className="text-center items-center">
                            <h2 className="text-3xl font-extrabold text-gray-100">
                                {client.name}
                            </h2>
                            <p className="mt-4 text-xl text-indigo-200">
                                Send files securely to {client.campus} {client.city}, {client.state} {client.zip}.
                            </p>
                            {client.phone &&
                            <div className="mt-2 flex place-content-center">
                                <div className="flex-shrink-0">
                                    <svg
                                        className="h-6 w-6 text-indigo-100"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                                    </svg>
                                </div>
                                <div className="ml-2 text-base text-indigo-100">
                                    <p>
                                        <a className="hover:text-white" href={`tel:${client.phone}`}>
                                            <PatternFormat value={client.phone} displayType={'text'} format="(###) ### ####" />
                                        </a>
                                    </p>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                {/* Body */}
                <div className="mt-8 bg-gray-100 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
                    <div className="relative">
                        <div
                            className="absolute inset-0 h-1/2 bg-gradient-to-r from-indigo-500 via-indigo-700 to-indigo-900"></div>
                        <div className="relative max-w-full lg:max-w-5xl mx-auto px-3">
                            <div className="mx-auto rounded-xl shadow-lg overflow-hidden md:max-w-none lg:flex">
                                {/* Uploader */}
                                <div className="flex-1 bg-white px-3 py-8 lg:p-12">
                                    <Dropzone
                                        addClassNames={{dropzone: 'h-full max-h-96 overflow-y-auto border border-gray-300 rounded-md'}}
                                        classNames={{
                                            inputLabel: 'text-indigo-500 hover:text-indigo-600 flex items-center justify-center absolute inset-0 cursor-pointer',
                                            inputLabelWithFiles: 'my-3 mx-3 cursor-pointer uppercase text-sm text-indigo-500 hover:text-indigo-600 flex self-start'
                                        }}
                                        getUploadParams={getUploadParams}
                                        onChangeStatus={handleChangeStatus}
                                        autoUpload={false}
                                        canRestart={false}
                                        inputContent="Drop files here or click to browse"
                                    />
                                </div>
                                {/* Form */}
                                <div className="flex-1 bg-white px-3 py-8 lg:p-12">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        validateOnChange={false}
                                        onSubmit={onSubmit}
                                    >
                                        {({errors, touched, isSubmitting}) => (
                                            <Form>
                                                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                    {/* Name */}
                                                    <div className="sm:col-span-6">
                                                        <label htmlFor="name"
                                                               className="block text-sm font-medium text-gray-700">
                                                            Your name
                                                        </label>
                                                        <div className="mt-1 relative rounded-md shadow-sm">
                                                            <div
                                                                className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                                <svg className="h-5 w-5 text-gray-400"
                                                                     xmlns="http://www.w3.org/2000/svg"
                                                                     fill="currentColor"
                                                                     viewBox="0 0 20 20"
                                                                     stroke="currentColor"
                                                                     aria-hidden="true">
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        strokeWidth="2"
                                                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                                                                </svg>
                                                            </div>
                                                            <Field
                                                                type="text"
                                                                name="name"
                                                                id="name"
                                                                autoComplete="name"
                                                                className="pl-10 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Email address */}
                                                    <div className="sm:col-span-6">
                                                        <label
                                                            htmlFor="email"
                                                            className="block text-sm font-medium text-gray-700">
                                                            Email address
                                                        </label>
                                                        <div className="mt-1 relative rounded-md shadow-sm">
                                                            <div
                                                                className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                                <svg
                                                                    className="h-5 w-5 text-gray-400"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                    aria-hidden="true">
                                                                    <path
                                                                        d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"/>
                                                                    <path
                                                                        d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"/>
                                                                </svg>
                                                            </div>
                                                            <Field
                                                                id="email"
                                                                name="email"
                                                                type="email"
                                                                autoComplete="email"
                                                                className="pl-10 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                            />
                                                        </div>
                                                        <p className="mt-2 text-sm text-gray-500">
                                                            Get notified when your files are received.
                                                        </p>
                                                    </div>
                                                    {/* Message */}
                                                    <div className="sm:col-span-6">
                                                        <label
                                                            htmlFor="message"
                                                            className="block text-sm font-medium text-gray-700">
                                                            Message
                                                        </label>
                                                        <div className="mt-1 relative rounded-md shadow-sm">
                                                            <Field
                                                                id="message"
                                                                name="message"
                                                                as="textarea"
                                                                rows="3"
                                                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Submit button */}
                                                    <div className="sm:col-span-6">
                                                        <button
                                                            type="submit"
                                                            disabled={isSubmitting || files.length === 0}
                                                            className="mt-1 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                            Upload {files.length === 0 ? '' : files.length === 1 ? '1 file' : `${files.length} files`}
                                                        </button>
                                                    </div>
                                                    {/* Error messages */}
                                                    {(errors.name || errors.email || initialValues.error)
                                                    && <div className="sm:col-span-6">
                                                        {errors.name && touched.name
                                                        &&
                                                        <p className="text-sm text-red-600"><ErrorMessage name="name"/>
                                                        </p>}
                                                        {errors.email && touched.email
                                                        &&
                                                        <p className="text-sm text-red-600"><ErrorMessage name="email"/>
                                                        </p>}
                                                        {initialValues.error
                                                        &&
                                                        <p className="text-sm text-red-600">{initialValues.error}</p>}
                                                    </div>
                                                    }
                                                    {/* Confirmation */}
                                                    {uploadCompleted &&
                                                    <div className="sm:col-span-6">
                                                        <div className="rounded-md bg-green-50 p-4">
                                                            <div className="flex">
                                                                <div className="flex-shrink-0">
                                                                    <svg className="h-5 w-5 text-green-400"
                                                                         xmlns="http://www.w3.org/2000/svg"
                                                                         viewBox="0 0 20 20"
                                                                         fill="currentColor"
                                                                         aria-hidden="true">
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                                                            clipRule="evenodd"/>
                                                                    </svg>
                                                                </div>
                                                                <div className="ml-3">
                                                                    <p className="text-sm font-medium text-green-800">
                                                                        Successfully uploaded.
                                                                    </p>
                                                                </div>
                                                                <div className="ml-auto pl-3">
                                                                    <div className="-mx-1.5 -my-1.5">
                                                                        <button
                                                                            onClick={() => {
                                                                                setUploadCompleted(false)
                                                                            }}
                                                                            className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600">
                                                                            <span className="sr-only">Dismiss</span>
                                                                            <svg className="h-5 w-5"
                                                                                 xmlns="http://www.w3.org/2000/svg"
                                                                                 viewBox="0 0 20 20"
                                                                                 fill="currentColor"
                                                                                 aria-hidden="true">
                                                                                <path
                                                                                    fillRule="evenodd"
                                                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                                                    clipRule="evenodd"/>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        <div className="mt-8">
                            <p className="text-sm text-gray-500 text-center">
                                <a
                                    className="hover:text-indigo-600"
                                    href="https://residexsoftware.com/software/rtasks"
                                    target="_blank"
                                    rel="noreferrer">
                                    Powered by RTasks
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Uploader;
