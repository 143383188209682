/**
 * 404 Not found page.
 */
const NotFound = () => {
    return (
        <>
            <div className="h-screen w-screen bg-gradient-to-r from-indigo-500 via-indigo-700 to-indigo-900 flex justify-center content-center flex-wrap">
                <div className="text-center items-center">
                    <h2 className="text-3xl font-extrabold text-gray-100">
                        404
                    </h2>
                    <p className="mt-4 text-xl text-indigo-200">
                        The page you are looking for could not be found.
                    </p>
                </div>
            </div>
        </>
    );
};

export default NotFound;
