/**
 * Application entry point.
 */
import React from 'react';
import { createRoot } from 'react-dom/client';

// Styles
import 'typeface-inter';
import './index.css';
import 'react-dropzone-uploader/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);
