/**
 * Main component that sets up state and handles routing.
 */
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import NotFound from './NotFound';
import Uploader from './Uploader';

const App = () => (
    <>
        <BrowserRouter>
            <Routes>
                <Route path="/:url" element={<Uploader/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </BrowserRouter>
    </>
);

export default App;
